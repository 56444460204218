import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth";
import map from "@/store/modules/map";
import nav from "@/store/modules/nav";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    map,
    nav,
  },
});
