<template>
  <div>
    <div class="slider">
      <label for="customRange3" class="form-label">Number of objects: {{ numberOfObjects }}</label>
      <input @input="numOfObjects($event)" type="range" class="form-range" min="1000" max="1000000" step="1000" id="customRange3" />
    </div>
    <div id="map" class="rounded-4 full m-2" style="width: 98vw; height: 98vh"></div>
    <transition name="slide-fade-right">
      <app-legenda v-if="legendaState" class="legenda" />
    </transition>
  </div>
</template>

<script>
import { Map, View } from "ol";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import Polygon from "ol/geom/Polygon";
import LineString from "ol/geom/LineString";
import { getCenter } from "ol/extent";
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style";
import { Cluster, OSM, Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { boundingExtent } from "ol/extent";
import { useGeographic, transformExtent, fromLonLat } from "ol/proj";
import AppLegenda from "@/components/Legenda.vue";
import { getterTypes as mapGetterTypes } from "@/store/modules/map";
import { mutationTypes as mapMutationTypes } from "@/store/modules/map";
import { getterTypes as navGetterTypes } from "@/store/modules/nav";
import { mapGetters } from "vuex";
import supercluster from "supercluster";
import { actionTypes } from "@/store/modules/map";

useGeographic();
export default {
  name: "open-layers",
  data() {
    return {
      map: null,
      numberOfObjects: 1000,
      index: new supercluster({
        radius: 100,
        maxZoom: 20,
      }),
      markers: [],
      polygons: [],
      polylines: [],
      styleCache: {},
      pointStyle: new Style({
        image: new CircleStyle({
          radius: 7,
          stroke: new Stroke({
            color: "#fff",
          }),
          fill: new Fill({
            color: "#3399CC",
          }),
        }),
      }),
      polygonStyle: new Style({
        fill: new Fill({
          color: "rgba(0, 0, 255, 0.3)",
        }),
      }),
      polylineStyle: new Style({
        stroke: new Stroke({
          color: "black",
          width: 2,
        }),
        geometry: function (feature) {
          var originalFeature = feature.get("features");
          return originalFeature[0].getGeometry();
        },
      }),
      clusterStyle: new Style({}),
      clusters: new VectorLayer({
        style: (feature) => {
          if (feature) {
            // let size = feature.get("features").length;
            let size = feature.get("point_count_abbreviated");
            if (size == undefined) {
              size = 1;
            }
            let style = this.styleCache[size];
            if (!style) {
              style = new Style({
                image: new CircleStyle({
                  radius: 10,
                  stroke: new Stroke({
                    color: "#fff",
                  }),
                  fill: new Fill({
                    color: "#3399CC",
                  }),
                }),
                text: new Text({
                  text: size.toString(),
                  fill: new Fill({
                    color: "#fff",
                  }),
                }),
              });
              this.styleCache[size] = style;
            }
            return style;
          }
        },
      }),
      polygonsLayer: new VectorLayer({
        style: null,
      }),
      polylinesLayer: new VectorLayer({
        style: (feature) => {
          if (feature) {
            let style = new Style({
              stroke: new Stroke({
                color: "black",
                width: 2,
              }),
            });
            return style;
          }
        },
      }),
    };
  },
  methods: {
    numOfObjects(e) {
      this.numberOfObjects = e.target.value;
      this.update();
    },
    update() {
      if (this.polygons.length > 0) {
        var bounds = this.map.getView().calculateExtent(this.map.getSize());
        let features = [];
        let clusterArray = this.index.getClusters(bounds, this.map.getView().getZoom());
        for (var i = 0; i < clusterArray.length; ++i) {
          const feature = new Feature({ geometry: new Point(clusterArray[i].geometry.coordinates) });
          feature.setProperties(clusterArray[i]["properties"]);
          features.push(feature);
        }
        const sourceMarkers = new VectorSource({ features: features });
        this.clusters.setSource(sourceMarkers);

        var polygonIntersection = [];
        if (this.polygons.length > 0) {
          const source = this.polygonsLayer.getSource();
          source.forEachFeatureIntersectingExtent(bounds, function (feature) {
            polygonIntersection.push(feature);
          });
        }
        if (polygonIntersection.length < this.numberOfObjects) {
          polygonIntersection.forEach((feature) => {
            this.clusters.setVisible(false);
            feature.setStyle(this.polygonStyle);
          });
        } else {
          polygonIntersection.forEach((feature) => {
            this.clusters.setVisible(true);
            feature.setStyle(null);
          });
        }
      }
    },
    initMap() {
      this.map = new Map({
        target: "map",
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
          this.polygonsLayer,
          // this.polylinesLayer,
          this.clusters,
        ],
        renderer: "webgl",
        view: new View({
          center: [5.819444616486, 51.846152313044],
          zoom: 15,
        }),
      });
      this.map.on("click", (e) => {
        const clickedFeatures = this.map.getFeaturesAtPixel(e.pixel);
        console.log(clickedFeatures);
        if (clickedFeatures.length) {
          // Get clustered Coordinates
          const features = clickedFeatures[0].get("features");
          if (features.length > 1) {
            const extent = boundingExtent(
              features.map((r) => {
                return r.getGeometry().getCoordinates();
              })
            );
            this.map.getView().fit(extent, { duration: 1000, padding: [50, 50, 50, 50] });
          }
        }
      });
      this.map.on("moveend", () => {
        this.update();
        // console.log("start");
        // var extent = this.map.getView().calculateExtent(this.map.getSize());
        // var polygonIntersection = [];
        // if (this.polygons.length > 0) {
        //   const source = this.polygonsLayer.getSource();
        //   source.forEachFeatureIntersectingExtent(extent, function (feature) {
        //     polygonIntersection.push(feature);
        //   });
        // }
        // console.log(polygonIntersection);
        // if (polygonIntersection.length < 40) {
        //   polygonIntersection.forEach((feature) => {
        //     feature.setStyle(this.polygonStyle);
        //   });
        // } else {
        //   polygonIntersection.forEach((feature) => {
        //     feature.setStyle(null);
        //   });
        // }
      });
    },
    drawObjects(val) {
      val.forEach((obj) => {
        const typeRegx = /^[^(]*/;
        const type = typeRegx.exec(obj.points);
        //////////////// DRAW MARKERS  //////////////////////////
        if ((obj.fields.objects_pin_type === "marker" || !obj.fields.objects_pin_type) && obj.fields.objects_points) {
          const myRe = /\((.*)/gm;
          const myArray = myRe.exec(obj.fields.objects_points);
          const pointsCouple = myArray[1].slice(0, -2).trim().split(",");
          if (!isNaN(Number(pointsCouple[0])) && !isNaN(Number(pointsCouple[1]))) {
            const coordinates = [Number(pointsCouple[1]), Number(pointsCouple[0])];
            const feature = new Feature({ geometry: new Point(coordinates), filter: obj.filter });
            this.markers.push(feature);
          }
        }
        // DRAW POLYGONS
        if (type[0] === "POLYGON") {
          const pointsRegx = /(?<=\(\().*?(?=\)\))/;
          const pointsStr = pointsRegx.exec(obj.points);
          const pointsArr = pointsStr[0].split(",");
          const coordinates = pointsArr.map((point) => {
            const pointArr = point.split(" ");
            const numPoint = [Number(pointArr[0]), Number(pointArr[1])];
            return numPoint;
          });
          const feature = new Feature({ geometry: new Polygon([coordinates]), filter: obj.filter, id: obj.id });
          this.polygons.push(feature);
        }
        //////////////// DRAW MULTIPOLYGONS  //////////////////////////
        if (type[0] === "MULTIPOLYGON") {
          const polyRegx = /(?<=\().*?(?=\))/g;

          const polyStrArr = obj.points.match(polyRegx);
          polyStrArr[0] = polyStrArr[0].replaceAll("(", "");
          const polyArr = polyStrArr.map((polygon) => {
            const pointsStrArr = polygon.split(",");
            const pointsArr = pointsStrArr.map((point) => {
              const coordinates = point.split(" ");
              return [Number(coordinates[0]), Number(coordinates[1])];
            });
            return pointsArr;
          });
          const feature = new Feature({ geometry: new Polygon(polyArr), filter: obj.filter, id: obj.id });
          this.polygons.push(feature);
        }
        //////////////// DRAW POLYLINES  //////////////////////////

        if (obj.fields.objects_pin_type === "polyline") {
          if (obj.fields.objects_points) {
            let pointsArr = obj.fields.objects_points.trim().split(")");
            let finalPointsArr = [];
            pointsArr.forEach((pointStr) => {
              if (pointStr !== "") {
                const myRe = /\((.*)/gm;
                const myArray = myRe.exec(pointStr);
                const pointsCouple = myArray[1].trim().split(",");
                const point = [Number(pointsCouple[1]), Number(pointsCouple[0])];
                finalPointsArr.push(point);
              }
            });
            const feature = new Feature({ geometry: new LineString(finalPointsArr), filter: obj.filter, id: obj.id });
            this.polylines.push(feature);
            // polylines.push({ coordinates: finalPointsArr, id: obj.id, filter: obj.filter });
          }
        }
      });
      // const sourceMarkers = new VectorSource({ features: [...this.polylines, ...this.markers, ...this.polygons] });
      const sourceMarkers = new VectorSource({ features: this.polygons });
      const sourcePolygons = new VectorSource({ features: this.polygons });
      // const sourcePolylines = new VectorSource({ features: this.polylines });
      // console.log("here");
      const clusterSource = new Cluster({
        distance: 80,
        minDistance: 20,
        source: sourceMarkers,
        geometryFunction: (feature) => {
          var geometry = feature.getGeometry();
          var type = geometry.getType();
          if (type == "Point") {
            return geometry;
          }
          if (type == "LineString") {
            return new Point(geometry.getCoordinateAt(0.5));
          }
          if (type == "Polygon") {
            return geometry.getInteriorPoint();
          } else {
            return new Point(getCenter(feature.getGeometry().getExtent()));
          }
        },
      });
      let points = [];
      this.polygons.map((feature) => {
        var geometry = feature.getGeometry();
        var type = geometry.getType();
        if (type == "Point") {
          points.push({
            geometry: {
              type: "Point",
              coordinates: geometry.getCoordinates().slice(0, -1),
              id: feature.id,
              filter: feature.filter,
            },
          });
        }
        if (type == "LineString") {
          points.push({
            geometry: {
              type: "Point",
              coordinates: geometry.getCoordinateAt(0.5).getCoordinates().slice(0, -1),
              id: feature.id,
              filter: feature.filter,
            },
          });
        }
        if (type == "Polygon") {
          points.push({
            geometry: {
              type: "Point",
              coordinates: geometry.getInteriorPoint().getCoordinates().slice(0, -1),
              id: feature.id,
              filter: feature.filter,
            },
          });
        } else {
          points.push({
            geometry: {
              type: "Point",
              coordinates: getCenter(feature.getGeometry().getExtent()).getCoordinates().slice(0, -1),
              id: feature.id,
              filter: feature.filter,
            },
          });
        }
      });

      this.index.load(points);
      // this.clusters.setSource(clusterSource);
      this.polygonsLayer.setSource(sourcePolygons);
      this.update();
      // this.polylinesLayer.setSource(sourcePolylines);
      // const extent = boundingExtent(this.markers.map((r) => r.getGeometry().getCoordinates()));
      // this.map.getView().fit(extent, { duration: 1000, padding: [50, 50, 50, 50] });
    },
    removeObjects(val) {
      const filteredMarkers = this.markers.filter((feature) => {
        return feature.getProperties().filter !== val;
      });
      this.markers = filteredMarkers;
      const filteredPolygons = this.polygons.filter((feature) => {
        return feature.getProperties().filter !== val;
      });
      this.polygons = filteredPolygons;
      const filteredPolylines = this.polylines.filter((feature) => {
        return feature.getProperties().filter !== val;
      });
      this.polylines = filteredPolylines;
      const sourceMarkers = new VectorSource({ features: this.markers });
      const sourcePolygons = new VectorSource({ features: this.polygons });
      const sourcePolylines = new VectorSource({ features: this.polylines });
      const clusterSource = new Cluster({
        source: sourceMarkers,
      });
      this.clusters.setSource(clusterSource);
      this.polygonsLayer.setSource(sourcePolygons);
      this.polylinesLayer.setSource(sourcePolylines);
      this.$store.commit(mapMutationTypes.isLoading, false);
      this.$store.commit(mapMutationTypes.filterRemoved, 0);
    },
  },
  computed: {
    ...mapGetters({
      newObjects: mapGetterTypes.newObjects,
      legendaState: navGetterTypes.legendaState,
      filterRemovedState: mapGetterTypes.filterRemovedState,
    }),
  },
  watch: {
    newObjects(val) {
      this.drawObjects(val);
    },
    filterRemovedState: function (val) {
      if (val !== null) {
        this.removeObjects(val);
      }
    },
  },
  components: {
    AppLegenda,
  },
  mounted() {
    this.initMap();
    this.$store.dispatch(actionTypes.filters);
  },
};
</script>
<style>
.legenda {
  position: absolute;
  top: 100px;
  right: 50px;
  z-index: 3;
}
.slider {
  position: absolute;
  bottom: 100px;
  left: 50px;
  background: white;
  padding: 10px;
  border-radius: 20px;
  z-index: 3;
}
/* /////////////////////////////////// */
.slide-fade-right-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-right-leave-active {
  transition: all 0.1s ease-in;
}
.slide-fade-right-enter, .slide-fade-right-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(20px);
  opacity: 0;
}
</style>
