import axios from "axios";
import { getItem } from "@/helpers/persistanceStorage";
let BASE_URL = process.env.VUE_APP_API_BASE_URL;

const getFilters = () => {
  const bodyFormData = new FormData();
  bodyFormData.set("version", "9.6");
  bodyFormData.set("access_token", getItem("accessToken"));
  return axios.post(BASE_URL + "getFilters/", bodyFormData);
};

const getObjects = async (data) => {
  let requests = [];
  console.log(data[1]);
  for (let i = 1; i <= data[1]; i++) {
    const bodyFormData = new FormData();
    bodyFormData.set("version", "9.6");
    bodyFormData.set("access_token", getItem("accessToken"));
    bodyFormData.set("page", i);
    bodyFormData.set("selected_filter", data[0]);
    requests.push(axios.post(BASE_URL + "getObjectsInternal/", bodyFormData));
  }
  const respond = await axios.all(requests).then(
    axios.spread((...res) => {
      console.log(res);
      return res;
    })
  );
  console.log(respond);
  return respond;
};

export default {
  getFilters,
  getObjects,
};
