<template>
  <div>
    <div class="row">
      <app-object-menu v-if="!isSignin" class="position-absolute object-menu" style="z-index: 1" />
      <nav class="rounded-4" v-if="!isSignin"><router-link to="/google-map">Google Map</router-link> | <router-link to="/map-box">MapBox</router-link> | <router-link to="/open-layers">Open Layers</router-link></nav>
    </div>
    <router-view />
  </div>
</template>
<script>
import { actionTypes } from "@/store/modules/map";
import { getterTypes as navGetterTypes } from "@/store/modules/nav";
import { mutationTypes as navMutationTypes } from "@/store/modules/nav";
import { mapGetters } from "vuex";
import AppObjectMenu from "@/components/ObjectsMenu.vue";

export default {
  data() {
    return {};
  },

  computed: {
    isSignin() {
      return this.$route.name === "signin";
    },
  },
  components: {
    AppObjectMenu,
  },
  mounted() {
    console.log("Is Autodeployed");
  },
};
</script>
<style scoped>
.object-menu {
  top: 30px;
  left: 30px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 10px;
  position: absolute;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  width: 400px;
  color: #6e7c74;
  background-color: rgba(151, 149, 149, 0.5);
}

nav a {
  color: #6e7c74;
  text-decoration: none;
}

nav a.router-link-exact-active {
  text-decoration: none;
  color: rgb(255, 255, 255);
}
</style>
