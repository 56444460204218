import mapApi from "@/api/map";

const state = {
  zoomLevel: 17,
  activeLayers: [],
  filters: [],
  isLoading: false,
  objects: [],
  filterRemoved: 0,
  inspections: [],
  objectClicked: null,
  pointerPos: null,
  noFiltersSelected: false,
  newObjects: [],
};
export const mutationTypes = {
  clearObjects: "[map] clearObjects",
  noFiltersSelected: "[map] NoFiltersSelected",
  pointerPos: "[map] pointerPos",
  zoomChange: "[map] zoomChange",
  activeLayersChange: "[map] activeLayersChange",
  objectClicked: "[map] ObjectClicked",
  isLoading: "[map] IsLoading",
  filtersStart: "[map] FiltersStart",
  filtersSuccess: "[map] FiltersSuccess",
  filtersFailure: "[map] FiltersFailure",
  filterRemoved: "[map] FilterRemoved",

  objectsStart: "[map] ObjectsStart",
  objectsSuccess: "[map] ObjectsSuccess",
  objectsFailure: "[map] ObjectsFailure",
  objectsRemove: "[map] ObjectsRemove",

  inspectionsStart: "[map] InspectionsStart",
  inspectionsSuccess: "[map] InspectionsSuccess",
  inspectionsFailure: "[map] InspectionsFailure",
};

export const actionTypes = {
  filters: "[map] filters",
  objects: "[map] objects",
  inspections: "[map] inspections",
};

export const getterTypes = {
  noFiltersSelected: "[map] NoFiltersSelected",
  objectClicked: "[map] objectClicked",
  zoomState: "[map] zoomState",
  activeLayersState: "[map] activeLayersState",
  filtersState: "[map] filtersState",
  objectsState: "[map] objectsState",
  filterRemovedState: "[map] filterRemovedState",
  isLoadingState: "[map] isLoadingState",
  inspectionsState: "[map] inspectionsState",
  pointerPos: "[map] pointerPos",
  newObjects: "[map] newObjects",
};

const getters = {
  [getterTypes.newObjects]: (state) => {
    return state.newObjects;
  },
  [getterTypes.noFiltersSelected]: (state) => {
    return state.noFiltersSelected;
  },
  [getterTypes.pointerPos]: (state) => {
    return state.pointerPos;
  },
  [getterTypes.objectClicked]: (state) => {
    return state.objectClicked;
  },
  [getterTypes.isLoadingState]: (state) => {
    return state.isLoading;
  },
  [getterTypes.objectsState]: (state) => {
    return state.objects;
  },
  [getterTypes.filtersState]: (state) => {
    return state.filters;
  },
  [getterTypes.filterRemovedState]: (state) => {
    return state.filterRemoved;
  },
  [getterTypes.zoomState]: (state) => {
    return state.zoomLevel;
  },
  [getterTypes.activeLayersState]: (state) => {
    return state.activeLayers;
  },
  [getterTypes.inspectionsState]: (state) => {
    return state.inspections;
  },
};

const mutations = {
  [mutationTypes.clearObjects](state) {
    state.newObjects = [];
  },
  [mutationTypes.noFiltersSelected](state, payload) {
    state.noFiltersSelected = payload;
  },
  [mutationTypes.isLoading](state, payload) {
    state.isLoading = payload;
  },
  [mutationTypes.pointerPos](state, payload) {
    state.pointerPos = payload;
  },
  [mutationTypes.objectClicked](state, payload) {
    state.objectClicked = payload;
  },
  [mutationTypes.zoomChange](state, payload) {
    state.zoomLevel = payload;
  },
  [mutationTypes.activeLayersChange](state, payload) {
    state.activeLayers = payload;
  },

  [mutationTypes.filtersStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.filtersSuccess](state, payload) {
    state.isLoading = false;
    state.filters = payload;
  },
  [mutationTypes.filtersFailure](state) {
    state.isLoading = false;
    state.filters = null;
  },
  [mutationTypes.filterRemoved](state, payload) {
    state.filterRemoved = payload;
  },
  [mutationTypes.objectsStart](state) {
    state.isLoading = true;
  },

  [mutationTypes.objectsSuccess](state, payload) {
    const objects = payload[0].map((obj) => {
      obj.filter = payload[1];
      return obj;
    });
    const stateArr = [...state.objects];
    if (stateArr.length) {
      const stateIds = stateArr.map((e) => e.id);
      let filtered = objects.filter((obj) => {
        return !stateIds.includes(obj.id);
      });
      state.objects = [...stateArr, ...filtered];
      state.newObjects = filtered;
    } else {
      state.objects = objects;
      state.newObjects = objects;
    }
    state.isLoading = false;
  },
  [mutationTypes.objectsFailure](state) {
    state.isLoading = false;
  },
  [mutationTypes.objectsRemove](state, payload) {
    const obj = state.objects.filter((obj) => obj.filter !== payload);
    state.objects = obj;
  },

  [mutationTypes.inspectionsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.inspectionsSuccess](state, payload) {
    state.isLoading = false;
    state.inspections = payload;
  },
  [mutationTypes.inspectionsFailure](state) {
    state.isLoading = false;
    state.inspections = null;
  },
};
const actions = {
  [actionTypes.filters](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.filtersStart);
      mapApi
        .getFilters()
        .then((response) => {
          context.commit(mutationTypes.filtersSuccess, response.data.returndata.filters);
          resolve(response.data.returndata.filters);
        })
        .catch(() => {
          context.commit(mutationTypes.filtersFailure);
        });
    });
  },
  [actionTypes.objects](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.objectsStart);
      mapApi
        .getObjects(data)
        .then((response) => {
          let all = [];
          response.forEach((r) => {
            all.push(...r.data.returndata.objects);
          });
          console.log(all);
          context.commit(mutationTypes.objectsSuccess, [all, data[0]]);
          resolve(response[0].data.returndata);
        })
        .catch((error) => {
          console.log(error);
          context.commit(mutationTypes.objectsFailure);
        });
    });
  },

  [actionTypes.inspections](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.inspectionsStart);
      mapApi
        .getCustomInspectionFields()
        .then((response) => {
          context.commit(mutationTypes.inspectionsSuccess, response.data.returndata);
          resolve(response.data.returndata);
        })
        .catch(() => {
          context.commit(mutationTypes.inspectionsFailure);
        });
    });
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
};
