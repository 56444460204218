<template>
  <div id="map" class="rounded-4 full m-2" style="width: 98vw; height: 98vh"></div>
</template>
<script>
export default {
  name: "leaflet-map",
  data() {
    return {
      map: null,
    };
  },
  methods: {
    initMap() {
      const mapOptions = {
        center: [52.34367094502756, 5.619902260762144],
        zoom: 10,
      };
      this.map = new window.L.Map("map", mapOptions);
      let layer = new window.L.TileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png");
      this.map.addLayer(layer);
    },
  },
  mounted() {
    this.initMap();
  },
};
</script>
