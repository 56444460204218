<template>
  <div class="rounded-4 legend-menu shadow-sm border border-blue border-2">
    <div class="bg-light-blue rounded-4 p-0">
      <div class="row mx-0 mb-2 p-2 justify-content-end" style="cursor: move">
        <div class="col-7 d-flex align-items-center">
          <h5 class="h5 objects-title mb-0">Legenda</h5>
        </div>
        <div class="col-5">
          <div class="d-flex justify-content-end">
            <div class="border border-blue rounded-4 p-1 mx-1 button-menu">
              <a href="#">
                <span class="material-icons text-dark">unpublished</span>
              </a>
            </div>
            <div class="border border-blue rounded-4 p-1 mx-1 button-menu">
              <a href="#">
                <span class="material-icons text-dark">cached</span>
              </a>
            </div>
            <div class="border border-blue rounded-4 p-1 mx-1 button-menu">
              <a href="#">
                <span class="material-icons text-dark">create</span>
              </a>
            </div>
            <div class="close-button d-flex align-items-center ms-3">
              <button @click="close" class="btn p-0 rounded-circle">
                <span class="material-icons text-dark fw-bold">clear</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white p-2 mt-2 rounded-4 border border-blue border-2 border-start-0 border-end-0">
        <div class="table-wrapper-scroll-y my-custom-scrollbar table-objects">
          <div class="accordion accordion-flush" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" :id="'heading' + 1">
                <button class="accordion-button" :class="{ collapsed: false }" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + 1" aria-expanded="true" :aria-controls="'collapse' + 1">
                  <div id="form-check" class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                  </div>
                  <span class="material-icons md-18 me-1" style="color: #3ab53a">filters</span>
                  Filters
                </button>
              </h2>
              <div :id="'collapse' + 1" class="accordion-collapse collapse" :class="{ show: true }" :aria-labelledby="'heading' + 1" data-bs-parent="#accordionExample">
                <div v-if="loading" style="text-align: center; width: 100%; height: 100%; background: white">
                  <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <div :class="{ active: loading }">
                  <div class="accordion-body">
                    <div v-for="data in filters" :key="data.id" id="form-check" class="form-check">
                      <input @change="onChange($event)" class="form-check-input" type="checkbox" :value="data.id" :id="data.id" />
                      <p>{{ data.filter_name }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import {mutationTypes as navMutationTypes} from '@/store/modules/nav'
import { mutationTypes as mapMutationTypes } from "@/store/modules/map";
import { mapGetters } from "vuex";
import { getterTypes as mapGetterTypes } from "@/store/modules/map";
import { actionTypes } from "@/store/modules/map";

import "bootstrap/dist/js/bootstrap.min.js";
export default {
  name: "AppLegenda",

  data() {
    return {
      activeFilters: [],
      loading: null,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: mapGetterTypes.isLoadingState,
      filters: mapGetterTypes.filtersState,
    }),
  },
  watch: {
    isLoading(val) {
      this.loading = val;
    },
  },
  methods: {
    close() {
      // this.$store.commit(navMutationTypes.legendaChange, false);
    },
    onChange(e) {
      const currentFilter = this.filters.filter((filter) => filter.id === e.target.value);

      if (e.target.checked) {
        this.activeFilters.push(currentFilter[0].filter_id);
        this.$store.dispatch(actionTypes.objects, [currentFilter[0].filter_id, 1]).then((r) => {
          console.log(r);
          if (r.objectsInFilter) {
            console.log(r.objectsInFilter);
            const pages = Math.ceil(r.objectsInFilter / 10000);
            this.$store.dispatch(actionTypes.objects, [currentFilter[0].filter_id, pages]);
            // for (let i = 2; i <= pages; i++) {
            //   this.$store.dispatch(actionTypes.objects, [currentFilter[0].filter_id, i]);
            // }
          }
        });
        // this.$store.commit(navMutationTypes.objectChange, true);
      } else {
        this.loading = true;
        this.$store.commit(mapMutationTypes.isLoading, true);
        setTimeout(() => {
          this.activeFilters = this.activeFilters.filter((id) => id !== currentFilter[0].filter_id);

          this.$store.commit(mapMutationTypes.objectsRemove, currentFilter[0].filter_id);
          this.$store.commit(mapMutationTypes.filterRemoved, currentFilter[0].filter_id);
        }, 100);
      }
    },
  },
};
</script>

<style scoped>
.active {
  display: none;
}
.accordion-button:after {
  order: -2;
  margin-left: 0;
  margin-right: 0.5em;
}
.accordion-button #form-check {
  order: -2;
  margin-left: 0;
  margin-right: 0.5em;
}
.form-check-input {
  border-radius: 0.25em !important;
}
</style>
