import authApi from '@/api/auth'
import {setItem} from '@/helpers/persistanceStorage'

const state = {
  isSubmitting: false,
  isLoggedIn: null,
  isLoading: false,
  currentUser: null,
  validationErrors: null,
  domain: localStorage.getItem('domain'),
  hasRights: true,
}
export const mutationTypes = {
  validationErrors: '[auth] ValidationErrors',
  domain: '[auth] Domain',
  hasRights: '[auth] HasRights',

  registerStart: '[auth] RegisterStart',
  registerSuccess: '[auth] RegisterSuccess',
  registerFailure: '[auth] RegisterFailure',

  signInStart: '[auth] SignInStart',
  signInSuccess: '[auth] SignInSuccess',
  signInFailure: '[auth] SignInFailure',

  getCurrentUserStart: '[auth] getCurrentUserStart',
  getCurrentUserSuccess: '[auth] getCurrentUserSuccess',
  getCurrentUserFailure: '[auth] getCurrentUserFailure',
}
export const actionTypes = {
  register: '[auth] register',
  signIn: '[auth] signIn',
  getCurrentUser: '[auth] getCurrentUser',
}

export const getterTypes = {
  currentUser: '[auth] currentUser',
  isLoggedIn: '[auth] isLoggedIn',
  isAnonymous: '[auth] isAnonymous',
  domain: '[auth] GetDomain',
  hasRights: '[auth] HasRights',
}

const getters = {
  [getterTypes.hasRights]: (state) => {
    return state.hasRights
  },
  [getterTypes.domain]: (state) => {
    return state.domain
  },
  [getterTypes.currentUser]: (state) => {
    return state.currentUser
  },
  [getterTypes.isLoggedIn]: (state) => {
    return Boolean(state.isLoggedIn)
  },
  [getterTypes.isAnonymous]: (state) => {
    return state.isLoggedIn === false
  },
}

const mutations = {
  [mutationTypes.hasRights](state, payload) {
    state.hasRights = payload
  },
  [mutationTypes.validationErrors](state, payload) {
    state.validationErrors = payload
  },
  [mutationTypes.domain](state, payload) {
    state.domain = payload
  },
  [mutationTypes.registerStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.registerSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.currentUser = payload.username
  },
  [mutationTypes.registerFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },

  [mutationTypes.signInStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.signInSuccess](state, payload) {
    state.isSubmitting = false
    state.isLoggedIn = true
    state.currentUser = payload.username
    if (payload.access === 'approved') {
      if (
        !payload.access_rules.general_inspections.access_to.general_inspection[
          '9'
        ]
      ) {
        state.hasRights = false
      } else {
        state.hasRights = true
      }
    }
  },
  [mutationTypes.signInFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },

  [mutationTypes.getCurrentUserStart](state) {
    state.isLoading = true
  },
  [mutationTypes.getCurrentUserSuccess](state, payload) {
    state.isLoading = false
    state.isLoggedIn = true
    state.currentUser = payload
  },
  [mutationTypes.getCurrentUserFailure](state) {
    state.isLoading = false
    state.isLoggedIn = false
    state.currentUser = null
  },
}
const actions = {
  [actionTypes.register](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.registerStart)
      authApi
        .register(credentials)
        .then((response) => {
          context.commit(mutationTypes.registerSuccess, response.data.user)
          setItem('accessToken', response.data.returndata[0].access_token)
          resolve(response.data.user)
        })
        .catch((result) => {
          context.commit(
            mutationTypes.registerFailure,
            result.response.data.errors
          )
        })
    })
  },
  [actionTypes.signIn](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.signInStart)
      authApi
        .signIn(credentials)
        .then((response) => {
          context.commit(
            mutationTypes.signInSuccess,
            response.data.returndata[0]
          )
          setItem('accessToken', response.data.returndata[0].access_token)
          resolve(response)
        })
        .catch((result) => {
          console.log(result)
          // context.commit(
          //   mutationTypes.signInFailure,
          //   result.response.data.errors
          // )
        })
    })
  },
  [actionTypes.getCurrentUser](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getCurrentUserStart)
      authApi
        .getCurrentUser()
        .then((response) => {
          context.commit(
            mutationTypes.getCurrentUserSuccess,
            response.data.user
          )
          resolve(response.data.user)
        })
        .catch(() => {
          context.commit(mutationTypes.getCurrentUserFailure)
        })
    })
  },
}
export default {
  state,
  actions,
  mutations,
  getters,
}
