<template>
  <div>
    <div ref="map" id="google_map" class="rounded-4 full m-2" style="width: 98vw; height: 98vh"></div>
    <transition name="slide-fade-right">
      <app-legenda v-if="legendaState" class="legenda" />
    </transition>
  </div>
</template>

<script>
import AppLegenda from "@/components/Legenda.vue";
import { actionTypes } from "@/store/modules/map";
import { getterTypes as mapGetterTypes } from "@/store/modules/map";
import { mutationTypes as mapMutationTypes } from "@/store/modules/map";
import { mapGetters } from "vuex";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { getterTypes as navGetterTypes } from "@/store/modules/nav";
export default {
  name: "google-map",
  data() {
    return {
      map: null,
      figuresArr: [],
      markerCluster: null,
    };
  },
  computed: {
    ...mapGetters({
      newObjects: mapGetterTypes.newObjects,
      filterRemovedState: mapGetterTypes.filterRemovedState,
      legendaState: navGetterTypes.legendaState,
    }),
  },
  watch: {
    newObjects(val) {
      console.log(val);
      this.drawObjects(val);
    },
    filterRemovedState: function (val) {
      if (val !== null) {
        this.removeObjects(val);
      }
    },
  },
  methods: {
    initMap() {
      const mapOptions = {
        zoom: 8,
        center: { lat: 52.34367094502756, lng: 5.619902260762144 },
      };
      this.map = new window.google.maps.Map(document.getElementById("google_map"), mapOptions);
      const map = this.map;
      const markers = this.figuresArr;
      this.markerCluster = new MarkerClusterer({ map, markers });
    },
    drawObjects(val) {
      const bounds = new window.google.maps.LatLngBounds();
      let markers = [];
      val.forEach((obj) => {
        //////////////// DRAW MARKERS  //////////////////////////
        if ((obj.fields.objects_pin_type === "marker" || !obj.fields.objects_pin_type) && obj.fields.objects_points) {
          const myRe = /\((.*)/gm;
          const myArray = myRe.exec(obj.fields.objects_points);
          const pointsCouple = myArray[1].slice(0, -2).trim().split(",");
          if (!isNaN(Number(pointsCouple[0])) && !isNaN(Number(pointsCouple[1]))) {
            const point = {
              lat: Number(pointsCouple[0]),
              lng: Number(pointsCouple[1]),
            };
            const marker = new window.google.maps.Marker({
              position: point,
              title: obj.number,
              filterId: obj.filter,
            });
            if (marker.getPosition().lat() && marker.getPosition().lng()) {
              bounds.extend({
                lat: marker.getPosition().lat(),
                lng: marker.getPosition().lng(),
              });
              this.figuresArr.push(marker);
              markers.push(marker);
            }
          }
        }

        //////////////// DRAW POLYGONS  //////////////////////////

        if (obj.fields.objects_pin_type === "polygon") {
          if (obj.fields.objects_points) {
            let pointsArr = obj.fields.objects_points.trim().split(")");
            let finalPointsArr = [];
            pointsArr.forEach((pointStr) => {
              if (pointStr !== "") {
                const myRe = /\((.*)/gm;
                const myArray = myRe.exec(pointStr);
                const pointsCouple = myArray[1].trim().split(",");
                const point = {
                  lat: Number(pointsCouple[0]),
                  lng: Number(pointsCouple[1]),
                };
                finalPointsArr.push(point);
              }
            });

            let noDuplicatesArray = [];
            for (let i = 0; i < finalPointsArr.length; i++) {
              if (i < finalPointsArr.length - 1) {
                if (finalPointsArr[i].lng !== finalPointsArr[i + 1].lng && finalPointsArr[i].lat !== finalPointsArr[i + 1].lat) {
                  noDuplicatesArray.push(finalPointsArr[i]);
                }
              } else {
                noDuplicatesArray.push(finalPointsArr[i]);
              }
            }
            let firstPoint = noDuplicatesArray[0];
            let index = 0;
            for (let i = 0; i < noDuplicatesArray.length; i++) {
              if (i !== 0 && noDuplicatesArray[i].lat === firstPoint.lat && noDuplicatesArray[i].lng === firstPoint.lng) {
                index = i;
                break;
              }
            }
            let outerArr = noDuplicatesArray.splice(0, index + 1);
            let innerArr = [];
            if (noDuplicatesArray.length > 0) {
              innerArr = noDuplicatesArray;
              innerArr.pop();
            }
            const polygon = new window.google.maps.Polygon({
              paths: [outerArr, innerArr],
              strokeColor: "blue",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "rgba(0, 0, 255)",
              fillOpacity: 0.35,
              filterId: obj.filter,
              id: obj.id,
            });
            polygon.setMap(this.map);
            window.google.maps.event.addListener(polygon, "click", function () {
              alert(this.id);
            });
            this.figuresArr.push(polygon);

            bounds.extend(outerArr[0]);
          }
        }

        //////////////// DRAW POLYLINES  //////////////////////////

        if (obj.fields.objects_pin_type === "polyline") {
          if (obj.fields.objects_points) {
            let pointsArr = obj.fields.objects_points.trim().split(")");
            let finalPointsArr = [];
            pointsArr.forEach((pointStr) => {
              if (pointStr !== "") {
                const myRe = /\((.*)/gm;
                const myArray = myRe.exec(pointStr);
                const pointsCouple = myArray[1].trim().split(",");
                const point = {
                  lat: Number(pointsCouple[0]),
                  lng: Number(pointsCouple[1]),
                };
                finalPointsArr.push(point);
              }
            });

            const polyline = new window.google.maps.Polyline({
              path: finalPointsArr,
              strokeColor: "#000000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              filterId: obj.filter,
              id: obj.id,
              lat: obj.lat,
              lng: obj.lon,
              icons: [],
              map: this.map,
            });
            const polylineHit = new window.google.maps.Polyline({
              path: finalPointsArr,
              strokeColor: "#FFFFFF",
              strokeOpacity: 0.001,
              strokeWeight: 15,
              filterId: obj.filter,
              id: obj.id,
              map: this.map,
            });
            this.figuresArr.push(polyline);
            this.figuresArr.push(polylineHit);
            bounds.extend(finalPointsArr[0]);
          }
        }
      });
      const map = this.map;
      this.markerCluster.addMarkers(markers);
      console.log(this.markerCluster);
      if (!bounds.isEmpty()) {
        map.fitBounds(bounds);
      }
    },
    removeObjects(filterId) {
      if (this.hasFigure) {
        if (this.path.filterId === filterId) {
          this.path.setMap(null);
        }
      }
      if (filterId !== null) {
        let markersToRemove = [];
        this.figuresArr.map((figure) => {
          if (figure.filterId === filterId) {
            // this.markerCluster.removeMarker(figure)
            markersToRemove.push(figure);
            figure.setMap(null);
          }
        });
        this.markerCluster.removeMarkers(markersToRemove);
        this.figuresArr = this.figuresArr.filter((figure) => {
          return figure.filterId !== filterId;
        });
      }

      this.$store.commit(mapMutationTypes.isLoading, false);
      this.$store.commit(mapMutationTypes.filterRemoved, null);
    },
  },
  components: {
    AppLegenda,
  },
  mounted() {
    this.initMap();
    this.$store.dispatch(actionTypes.filters);
    this.$store.commit(mapMutationTypes.clearObjects);
  },
};
</script>
<style>
.legenda {
  position: absolute;
  top: 100px;
  right: 50px;
  z-index: 3;
}
/* /////////////////////////////////// */
.slide-fade-right-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-right-leave-active {
  transition: all 0.1s ease-in;
}
.slide-fade-right-enter, .slide-fade-right-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(20px);
  opacity: 0;
}
</style>
