<template>
  <ul class="error-messages">
    <li>
      {{ errorMessages }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'AppValidationErrors',
  props: ['validationErrors'],

  computed: {
    errorMessages() {
      return this.validationErrors
    },
  },
}
</script>
