import Vue from "vue";
import VueRouter from "vue-router";
import SignIn from "@/views/SignIn.vue";
import GoogleMap from "@/views/GoogleMap.vue";
import MapBox from "@/views/MapBox.vue";
import Leaflet from "@/views/Leaflet.vue";
import OpenLayers from "@/views/OpenLayers.vue";
import { getItem } from "@/helpers/persistanceStorage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "signin",
    component: SignIn,
  },
  {
    path: "/google-map",
    name: "googlemap",
    component: GoogleMap,
  },
  {
    path: "/map-box",
    name: "mapbox",
    component: MapBox,
  },
  {
    path: "/leaflet",
    name: "leaflet-map",
    component: Leaflet,
  },
  {
    path: "/open-layers",
    name: "openlayers",
    component: OpenLayers,
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach(async (to, from, next) => {
  if (to.name !== "signin" && !getItem("accessToken")) {
    next({ name: "signin" });
  } else {
    next();
  }

  // if the user is not authenticated, `next` is called twice
});
export default router;
