<template>
  <div>
    <div class="slider">
      <label for="customRange3" class="form-label">Number of objects: {{ numberOfObjects }}</label>
      <input @input="numOfObjects($event)" type="range" class="form-range" min="1000" max="1000000" step="1000" id="customRange3" />
    </div>
    <div id="map" class="rounded-4 full m-2" style="width: 98vw; height: 98vh"></div>
    <transition name="slide-fade-right">
      <app-legenda v-if="legendaState" class="legenda" />
    </transition>
  </div>
</template>
<script>
import mapbox from "mapbox-gl";
import { actionTypes } from "@/store/modules/map";
import "mapbox-gl/dist/mapbox-gl.css";
import AppLegenda from "@/components/Legenda.vue";
import { getterTypes as mapGetterTypes } from "@/store/modules/map";
import { mutationTypes as mapMutationTypes } from "@/store/modules/map";
import { mapGetters } from "vuex";
import { getterTypes as navGetterTypes } from "@/store/modules/nav";
import Supercluster from "supercluster";

export default {
  name: "map-box",
  data() {
    return {
      numberOfObjects: 1000,
      token: process.env.VUE_APP_MAPBOX_TOKEN,
      map: null,
      markersGeo: [],
      polygonsGeo: [],
      polylinesGeo: [],
      ////////////////////////
      locations: [],
      clusters: [],
      markers: [],
      clustersGeojson: {},
      clusterIndex: null,
      points: [],
      popup: new mapbox.Popup({ closeButton: false, closeOnClick: false }),
    };
  },
  computed: {
    ...mapGetters({
      newObjects: mapGetterTypes.newObjects,
      filterRemovedState: mapGetterTypes.filterRemovedState,
      legendaState: navGetterTypes.legendaState,
    }),
  },
  watch: {
    newObjects(val) {
      this.drawObjects(val);
    },
    filterRemovedState: function (val) {
      if (val !== null) {
        this.removeObjects(val);
      }
    },
  },
  methods: {
    numOfObjects(e) {
      this.numberOfObjects = e.target.value;
      this.updateClusters();
    },
    initMap() {
      mapbox.accessToken = this.token;
      this.map = new mapbox.Map({
        container: "map", // container ID
        style: "mapbox://styles/mapbox/streets-v11?optimize=true", // style URL
        center: [5.819444616486, 51.846152313044], // starting position [lng, lat]
        zoom: 12, // starting zoom
      });
      this.map.on("style.load", () => {
        ///////////// CLUSTER ////////////////

        this.clusterIndex = new Supercluster({
          radius: 100,
          maxZoom: 20,
        });

        this.map.on("moveend", () => {
          this.updateClusters();
        });
        this.updateClusters();

        /////////////////////////////

        this.map.setFog({});
        // ADD MARKERS
        this.map.loadImage("https://upload.wikimedia.org/wikipedia/commons/thumb/e/ed/Map_pin_icon.svg/176px-Map_pin_icon.svg.png", (error, image) => {
          if (error) throw error;
          this.map.addImage("custom-marker", image);

          // ADD POLYLINES
          this.map.addSource("polylines", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [],
            },
          });
          // ADD POLYGONS
          this.map.addSource("polygons", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [],
            },
          });
          this.map.addSource("outline", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [],
            },
          });
          // ADD POLYLINES LAYER
          this.map.addLayer({
            id: "lines",
            type: "line",
            source: "polylines",
            layout: {},
            paint: {
              "line-color": "#FF0000",
              "line-width": 2,
            },
          });

          // ADD POLYGONS LAYER
          this.map.addLayer({
            id: "polygons",
            type: "fill",
            source: "polygons",
            layout: {
              visibility: "none",
            },
            paint: {
              "fill-color": "blue",
              "fill-opacity": 0.3,
            },
          });
          // Add a black outline around the polygon.
          // ADD OUTLINE LAYER

          this.map.addLayer({
            id: "outline",
            type: "line",
            source: "outline",
            layout: {},
            paint: {
              "line-color": "#000",
              "line-width": 1,
            },
          });

          this.map.addLayer({
            id: "highlight",
            type: "fill",
            source: "outline",
            layout: {},
            paint: {
              "fill-color": "red",
              "fill-opacity": 0.3,
            },
          });

          // Add a GeoJSON source with 2 points
          this.map.addSource("markers", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [],
            },
          });

          // Add a symbol layer
          this.map.addLayer({
            id: "points",
            type: "symbol",
            source: "markers",
            layout: {
              "icon-image": "custom-marker",
              "icon-size": 0.1,
              "icon-allow-overlap": true,
              "text-anchor": "top",
            },
          });
        });

        this.map.on("click", "polygons", (e) => {
          const features = this.map.queryRenderedFeatures(e.point);
          for (var i = 0; i < features.length; i++) {
            if (features[i].layer.id === "polygons") {
              let coordinates = [];
              if (features[i].geometry.coordinates[0].length === 2) {
                coordinates = features[i].geometry.coordinates;
              } else {
                coordinates = features[i].geometry.coordinates[0];
              }
              const polygon = new window.L.Polygon(coordinates);
              const bounds = polygon.getBounds();
              const center = bounds.getCenter();
              this.popup.setLngLat([center.lat, center.lng]).setHTML(`Object ID: ${features[i].properties.id}`).addTo(this.map);
            }
          }
        });
        this.map.on("mouseenter", "clusters", () => {
          this.map.getCanvas().style.cursor = "pointer";
        });
        this.map.on("mouseleave", "clusters", () => {
          this.map.getCanvas().style.cursor = "";
        });

        this.map.on("mousemove", (e) => {
          const features = this.map.queryRenderedFeatures(e.point);
          for (var i = 0; i < features.length; i++) {
            if (features[i].layer.id === "polygons") {
              this.map.getSource("outline").setData(features[i]);
              break;
            }
            this.popup.remove();
            this.map.getSource("outline").setData({
              type: "Feature",
              features: [],
            });
          }
        });
      });
    },

    drawObjects(val) {
      let markers = [];
      let polygons = [];
      let polylines = [];
      let points = [];

      val.forEach((obj) => {
        const typeRegx = /^[^(]*/;
        const type = typeRegx.exec(obj.points);
        ///////////////// DRAW MARKERS  //////////////////////////
        if ((obj.fields.objects_pin_type === "marker" || !obj.fields.objects_pin_type) && obj.fields.objects_points) {
          const myRe = /\((.*)/gm;
          const myArray = myRe.exec(obj.fields.objects_points);
          const pointsCouple = myArray[1].slice(0, -2).trim().split(",");
          if (
            !isNaN(Number(pointsCouple[0])) &&
            !isNaN(Number(pointsCouple[1])) &&
            Number(pointsCouple[0]) !== 0 &&
            Number(pointsCouple[1]) !== 0 &&
            Number(pointsCouple[0]) > -99 &&
            Number(pointsCouple[0]) < 99 &&
            Number(pointsCouple[1]) > -99 &&
            Number(pointsCouple[1]) < 99
          ) {
            const point = {
              lat: Number(pointsCouple[0]),
              lng: Number(pointsCouple[1]),
              id: obj.id,
              filter: obj.filter,
            };
            // console.log(point);
            markers.push(point);
          }
        }
        //////////////// DRAW POLYGONS  //////////////////////////
        if (type[0] === "POLYGON") {
          const pointsRegx = /(?<=\(\().*?(?=\)\))/;
          const pointsStr = pointsRegx.exec(obj.points);
          const pointsArr = pointsStr[0].split(",");
          const coordinates = pointsArr.map((point) => {
            const pointArr = point.split(" ");
            const numPoint = [Number(pointArr[0]), Number(pointArr[1])];
            return numPoint;
          });
          polygons.push({
            type: "Feature",
            properties: { filter: obj.filter, id: obj.id },
            geometry: {
              type: "Polygon",
              coordinates: [coordinates],
            },
          });
        }
        //////////////// DRAW MULTIPOLYGONS  //////////////////////////
        if (type[0] === "MULTIPOLYGON") {
          const polyRegx = /(?<=\().*?(?=\))/g;

          const polyStrArr = obj.points.match(polyRegx);
          polyStrArr[0] = polyStrArr[0].replaceAll("(", "");
          const polyArr = polyStrArr.map((polygon) => {
            const pointsStrArr = polygon.split(",");
            const pointsArr = pointsStrArr.map((point) => {
              const coordinates = point.split(" ");
              return [Number(coordinates[0]), Number(coordinates[1])];
            });
            return pointsArr;
          });
          polygons.push({
            type: "Feature",
            properties: { filter: obj.filter, id: obj.id },
            geometry: {
              type: "MultiPolygon",
              coordinates: [polyArr],
            },
          });
        }

        //////////////// DRAW POLYLINES  //////////////////////////
        if (obj.fields.objects_pin_type === "polyline") {
          if (obj.fields.objects_points) {
            let pointsArr = obj.fields.objects_points.trim().split(")");
            let finalPointsArr = [];
            pointsArr.forEach((pointStr) => {
              if (pointStr !== "") {
                const myRe = /\((.*)/gm;
                const myArray = myRe.exec(pointStr);
                const pointsCouple = myArray[1].trim().split(",");
                const point = [Number(pointsCouple[1]), Number(pointsCouple[0])];
                finalPointsArr.push(point);
              }
            });
            polylines.push({ coordinates: finalPointsArr, id: obj.id, filter: obj.filter });
          }
        }
      });

      if (markers.length > 0) {
        markers.map((marker) => {
          const newMarker = {
            type: "Feature",

            geometry: {
              type: "Point",
              coordinates: [marker.lng, marker.lat],
              properties: { id: marker.id, filter: marker.filter },
            },
          };
          this.markersGeo.push(newMarker);
        });
      }

      this.polygonsGeo = [...this.polygonsGeo, ...polygons];

      const dataPolygons = {
        type: "FeatureCollection",
        features: this.polygonsGeo,
      };
      this.map.getSource("polygons").setData(dataPolygons);
      const dataMarkers = {
        type: "FeatureCollection",
        features: this.markersGeo,
      };
      this.points.push(...this.markersGeo);
      this.map.getSource("markers").setData(dataMarkers);

      polygons.map((feature) => {
        var type = feature.geometry.type;
        if (type == "Polygon") {
          const polygon = new window.L.Polygon(feature.geometry.coordinates);
          const bounds = polygon.getBounds();
          const center = bounds.getCenter();
          this.points.push({
            geometry: {
              type: "Point",
              coordinates: [center.lat, center.lng],
              properties: { id: feature.properties.id, filter: feature.properties.filter },
            },
          });
        }
        if (type == "MultiPolygon") {
          const polygon = new window.L.Polygon(feature.geometry.coordinates[0]);
          const bounds = polygon.getBounds();
          const center = bounds.getCenter();
          this.points.push({
            geometry: {
              type: "Point",
              coordinates: [center.lat, center.lng],
              properties: { id: feature.properties.id, filter: feature.properties.filter },
            },
          });
        }
      });

      const newPolylines = polylines.map((polyline) => {
        console.log(polyline);
        const figure = new window.L.Polyline(polyline.coordinates);
        const bounds = figure.getBounds();
        const center = bounds.getCenter();
        this.points.push({
          geometry: {
            type: "Point",
            coordinates: [center.lat, center.lng],
            properties: { id: polyline.id, filter: polyline.filter },
          },
        });
        return {
          type: "Feature",
          properties: { id: polyline.id, filter: polyline.filter },
          geometry: {
            type: "LineString",
            coordinates: polyline.coordinates,
          },
        };
      });

      this.polylinesGeo = [...this.polylinesGeo, ...newPolylines];

      const dataPolylines = {
        type: "FeatureCollection",
        features: this.polylinesGeo,
      };
      this.map.getSource("polylines").setData(dataPolylines);
      console.log(this.points);
      this.clusterIndex.load(this.points);
      this.updateClusters();
    },
    removeObjects(filterId) {
      const filteredMarkers = this.markersGeo.filter((marker) => marker.geometry.properties.filter !== filterId);
      this.markersGeo = filteredMarkers;
      const filteredPolygons = this.polygonsGeo.filter((polygon) => polygon.properties.filter !== filterId);
      this.polygonsGeo = filteredPolygons;
      const filteredPolylines = this.polylinesGeo.filter((polyline) => polyline.properties.filter !== filterId);
      this.polylinesGeo = filteredPolylines;
      const markersData = {
        type: "FeatureCollection",
        crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
        features: this.markersGeo,
      };
      const dataPolygons = {
        type: "FeatureCollection",
        features: this.polygonsGeo,
      };
      const dataPolylines = {
        type: "FeatureCollection",
        features: this.polylinesGeo,
      };
      console.log(this.points);
      const filteredPoints = this.points.filter((point) => {
        console.log(point);
        if (point.geometry.properties.filter !== filterId) {
          return true;
        }
        return false;
      });
      this.clusterIndex.load(filteredPoints);
      this.updateClusters();
      this.map.getSource("polygons").setData(dataPolygons);
      this.map.getSource("markers").setData(markersData);
      this.map.getSource("polylines").setData(dataPolylines);
      this.$store.commit(mapMutationTypes.isLoading, false);
      this.$store.commit(mapMutationTypes.filterRemoved, 0);
    },
    updateClusters() {
      if (this.markers.length) {
        this.markers.forEach(function (marker) {
          marker.remove();
        });
      }
      let bounds = this.map.getBounds();
      let zoom = this.map.getZoom();
      let objectsNum = 0;
      if (this.polygonsGeo.length > 0) {
        this.clustersGeojson = this.clusterIndex.getClusters([bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()], Math.floor(zoom));
        for (var i = 0; i < this.clustersGeojson.length; ++i) {
          var el = document.createElement("div");
          el.className = "flex items-center justify-center w-12 h-12 rounded-full text-center text-white font-bold shadow bg-cover cursor-pointer bg-center ";
          el.style.background = "#3498DB";
          el.style.borderRadius = "50%";
          el.style.width = "30px";
          el.style.height = "30px";
          el.style.border = "solid 2px";
          el.style.borderColor = "white";
          el.style.paddingTop = "3px";
          el.style.boxShadow = "0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0)";
          if (this.clustersGeojson[i].properties) {
            objectsNum += this.clustersGeojson[i].properties.point_count;
            el.innerHTML = this.clustersGeojson[i].properties.point_count_abbreviated;
            const marker = new mapbox.Marker(el).setLngLat(this.clustersGeojson[i].geometry.coordinates).addTo(this.map);
            this.markers.push(marker);
          } else {
            objectsNum += 1;
            el.innerHTML = "1";
            const marker = new mapbox.Marker(el).setLngLat(this.clustersGeojson[i].geometry.coordinates).addTo(this.map);
            this.markers.push(marker);
          }
        }
        // const boundingBox = new mapbox.LngLatBounds(bounds);
        if (objectsNum < this.numberOfObjects) {
          this.map.setLayoutProperty("polygons", "visibility", "visible");
          this.map.setLayoutProperty("points", "visibility", "visible");
          this.map.setLayoutProperty("lines", "visibility", "visible");
          if (this.markers.length) {
            this.markers.forEach(function (marker) {
              marker.remove();
            });
          }
        } else {
          this.map.setLayoutProperty("polygons", "visibility", "none");
          this.map.setLayoutProperty("points", "visibility", "none");
          this.map.setLayoutProperty("lines", "visibility", "none");
        }
      }
    },
  },
  components: {
    AppLegenda,
  },
  mounted() {
    this.initMap();
    this.$store.dispatch(actionTypes.filters);
  },
};
</script>
<style>
/* /////////////////////////////////// */
.slide-fade-right-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-right-leave-active {
  transition: all 0.1s ease-in;
}
.slide-fade-right-enter, .slide-fade-right-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(20px);
  opacity: 0;
}

.marker {
  width: 0;
  height: 0;
}

.marker span {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  color: #fff;
  background: #693;
  border: solid 2px;
  border-radius: 0 70% 70%;
  box-shadow: 0 0 2px #000;
  cursor: pointer;
  transform-origin: 0 0;
  transform: rotateZ(-135deg);
}
.mapboxgl-popup-content {
  border-radius: 10px;
  padding: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
}
.mapboxgl-popup-anchor-bottom > .mapboxgl-popup-tip {
  border-top-color: rgba(0, 0, 0, 0.3);
}
</style>
