export const getItem = (key) => {
  try {
    return window.$cookies.get(key)
  } catch (e) {
    console.error('Error in getting data from Cookies', e)
    return null
  }
}
export const setItem = (key, data) => {
  try {
    window.$cookies.set(key, data, '2h')
  } catch (e) {
    console.error('Error in setting data to Cookies', e)
  }
}
